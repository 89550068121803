import Content from '../../components/content';

function Webinar6() {
    return (
        <div>
            <Content
                title={`Webinar 6 "Course Evaluation" at International Psychoanalytic University Berlin GmbH (May 14, 2024)`}
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, img {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>

                        <p style={{ textIndent: '1.25em' }}>On May 14th, the International Psychoanalytic University Berlin GmbH in Germany hosted a webinar titled "Course Evaluation at IPU Berlin". The webinar featured speakers Lars Kuchinke and Phil Langer from the Team Quality Management and Organisation Development, along with Wiebke Stelling, head of accreditations, documents, and processes, as well as the alumni program and OD projects, and Christine Möllers, responsible for evaluations, events, and the mentoring program, led the discussion.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-6/4.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>The breakout session focused on evaluating the quality of studies and teaching from different perspectives:</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-6/8.png" alt="burnLogo" />
                        </div>
                        <ul>
                            <li>Group A: Participants considered the perspective of students and discussed criteria for a successful degree program. They also explored how students could recognize when these criteria are met.</li>
                            <li>Group B: Participants took the perspective of lecturers, identifying criteria for a successful degree program from their point of view. They also speculated on how students would recognize the fulfillment of these criteria.</li>
                        </ul>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-6/10.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>Each group engaged in short breakout sessions for 10 minutes, followed by a 5-minute discussion in the plenum.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-6/11.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>After the breakout sessions, participants shared and discussed their opinions on evaluating the quality of studies and teaching. The exchange likely provided valuable insights and perspectives from both students and lecturers, contributing to ongoing efforts to enhance the educational experience.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-6/20.png" alt="burnLogo" />
                        </div>
                    </div>
                } />
        </div>
    );
}

export default Webinar6;
