import Content from '../components/content';

function Courses() {
    return (
        <div>
            <Content
                title='Courses'
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, ul {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>
                        <p style={{ textIndent: '1.25em' }}>
                            <strong>1.BA course “Crisis Online Counseling” taught in Ukrainian (3 ECTS).</strong>
                        </p>
                        <p style={{ textIndent: '1.25em' }}>
                            <strong>
                                2. MA course “Mindfulness-Based Art Therapy with Trauma” taught in English (3 ECTS).
                            </strong>
                        </p>
                        <p style={{ textIndent: '1.25em' }}>
                            <strong>
                                3. PhD module “Working with Refugees and Survivors of War” taught in English (0,5 ECTS).
                            </strong>
                        </p>
                        <p style={{ textIndent: '1.25em' }}>
                            Partners will identify the aims, learning outcomes, content, etc. Every team will develop a schedule that will allow them to move forward. The prospective approach is that the teams will meet online once a week, share their results, and send new tasks for the next week. The EU leaders will mentor the EU participants.
                        </p>
                        <p style={{ textIndent: '1.25em' }}>
                            The courses have to face local challenges of (post)war trauma, so the materials can be scarce. The teams will also prepare the materials for the courses. The design of the materials will take as much if not more time than the course development. When the courses are developed, the teams will present them to the consortium for internal evaluation.
                        </p>
                    </div>
                } />
        </div>
    );
}

export default Courses;
