import Content from '../../components/content';

function Webinar3() {
    return (
        <div>
            <Content
                title={`Webinar 3 "Germany's Syllabus Development Experience" at International Psychoanalytic University Berlin GmbH (May 7, 2024)`}
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, img {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>
                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-3/b2.jpg" alt="burnLogo" />
                        </div>
                        <p style={{ textIndent: '1.25em' }}>Speakers: Dr. Phil Langer and Dr. Lars Kuchinke from the International Psychoanalytic University Berlin GmbH.</p>
                        <p style={{ textIndent: '1.25em' }}>Dr. Kuchinke's presentation sheds light on Germany's psychologist education system, highlighting its emphasis on openness, granting course authors full autonomy over content. Lecturers are esteemed as experts, entrusted by universities competing globally, where they integrate international psychological education trends. In German psychological education, achieving a delicate balance between theory and practice is paramount. This focus stems from laws governing psychologist training and national requirements prioritizing practical skill application, especially for future psychotherapists. Despite this, a potential shortage of psychologist-researchers looms on the horizon.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-3/b3.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>The syllabus serves as a critical communication channel, effectively conveying expectations and facilitating seamless feedback exchange between students and instructors.  Key course elements encompass in-depth descriptions, including university details, prerequisites, course overviews, and learning objectives. Grading policies outline acceptable late submissions and assignment resubmissions, along with permitted and prohibited actions for students.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-3/b4.jpg" alt="burnLogo" />
                        </div>
                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-3/b5.jpg" alt="burnLogo" />
                        </div>


                        <p style={{ textIndent: '1.25em' }}>Clear formulation of learning objectives is essential, employing Bloom's Taxonomy to ensure a diverse range of cognitive levels. Additionally.</p>
                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-3/b7.jpg" alt="burnLogo" />
                        </div>
                        <p style={{ textIndent: '1.25em' }}>
                            During the Q&A session:
                            <ul>
                                <li>Assessment responsibility and exam design rest with the lecturer.</li>
                                <li>Recent years have witnessed a shift towards practical-oriented education, notably in psychotherapy.</li>
                                <li>Assessing soft skills and intervention planning evolves through collaborative efforts.</li>
                            </ul>
                        </p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-3/b13.jpg" alt="burnLogo" />
                        </div>
                        <p style={{ textIndent: '1.25em' }}>
                            Expert advice:
                            <ul>
                                <li>Develop courses in areas of confidence, blending insights from practitioners and researchers.</li>
                                <li>Utilize resources like those provided by the University of Berkeley to enhance syllabus enrichment.</li>
                            </ul>
                        </p>
                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-3/b15.jpg" alt="burnLogo" />
                        </div>

                    </div>
                } />
        </div>
    );
}

export default Webinar3;
