import Content from '../../components/content';

function Webinar13() {
    return (
        <div>
            <Content
                title={`University Psychological Services and Support (June 13, 2024)`}
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, img {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>

                        <p style={{ textIndent: '1.25em' }}>This past June 13th, 2024, a valuable webinar titled "University Psychological Services and Support" took place. Hosted by Dr. Lars Kulchinke (IPU Berlin), Dr. Laura Pirsko (Latvia), and Kristel Laan-Saarik (Estonia), the session focused on fostering psychological resilience and well-being within universities.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-13/11.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>The webinar emphasized the importance of establishing comprehensive Center for Psychological Resilience and Wellbeing within universities. These centers offer a variety of support services to help students, faculty, and staff thrive.</p>
                        <p style={{ textIndent: '1.25em' }}>Understanding differencies between Projet's Center for Psychological Resilience and Wellbeing  and Resilience Centers that provided by Ministry of Social Policy was presented by Lars Kulchinke and supported by K. Bondar (Kryvyi Rih Pedagogical University (KSPU)</p>
                        <p style={{ textIndent: '1.25em' }}>Key Takeaway: University  Centers differ from those proposed by the Ministry of Social Policy. University centeres offer a different range of services, including research, educational programs, consultations, and practical assistance. Additionally, they focus on individual and institutional resilience development, and have greater autonomy due to a combination of funding sources.</p>
                        <p style={{ textIndent: '1.25em' }}>Building Effective University Resilience Centers had a Collaborative Discussion that moderated by Dr. Lars Kulchinke</p>
                        <p style={{ textIndent: '1.25em' }}>Olha Lovnys from Ternopil National University focus on this discussion delved into the structure and methods of operation for university resilience centers. Key questions addressed included:</p>
                        <ol style={{ marginLeft: '1.25em' }}>
                            <li style={{ marginLeft: '1.25em' }}>What services should these centers provide?</li>
                            <li style={{ marginLeft: '1.25em' }}>How can they collaborate with state-run centers and other organizations?</li>
                            <li style={{ marginLeft: '1.25em' }}>How will they be funded?</li>
                            <li style={{ marginLeft: '1.25em' }}>What human resources and infrastructure are needed?</li>
                        </ol>
                        <br />
                        <p style={{ textIndent: '1.25em' }}>The webinar highlighted the distinction between resilience (the ability to adapt and cope with challenges) and mental health (overall emotional and psychological well-being). Project's centers will play a crucial role in promoting mental health and well-being across the university community. They achieve this by offering various support services and conducting research to build stronger, more resilient communities.</p>
                        <p style={{ textIndent: '1.25em' }}>The webinar also featured discussions with representatives from various universities, highlighting their specific needs and interests:</p>
                        <p style={{ textIndent: '1.25em' }}>Ternopil University: Focused on the structure of counseling services and the use of short-term interventions.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-13/12.jpg" alt="burnLogo" />
                        </div>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-13/13.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>Kherson University: Emphasized the need for online counseling services to cater to a large student population.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-13/18.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>Horlivka Institute: Discussed the potential use of diagnostic tools to support those seeking help from the center.</p>
                        <p style={{ textIndent: '1.25em' }}>Melitopol University: Raised questions concerning the center's content and scope of work, as well as staff qualifications.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-13/15.jpg" alt="burnLogo" />
                        </div>
                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-13/16.jpg" alt="burnLogo" />
                        </div>
                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-13/17.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>Latvian University (Laura Pirsko): Shared their planning process for a center on their new campus.</p>
                        <p style={{ textIndent: '1.25em' }}>University of Tartu (Kristel Laan-Saarik): Underlined the importance of inclusive support services for students with special educational needs.</p>
                        <p style={{ textIndent: '1.25em' }}>The "University Psychological Services and Support" webinar offered valuable insights into the diverse needs of universities when establishing resilience centers. The focus on short-term interventions, online counseling, and inclusive support highlights the evolving nature of university mental health services. Collaboration among universities and experts paves the way for knowledge sharing and best practices in building effective resilience centers for students, faculty, and staff.</p>

                    </div>
                } />
        </div>
    );
}

export default Webinar13;
