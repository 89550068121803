import Content from '../components/content';

function UPC() {
    return (
        <div>
            <Content
                title='Center for Psychological Resilience and Wellbeing'
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, ul {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>
                        <p style={{ textIndent: '1.25em' }}>Throughout the duration of the project, our aim is to enhance the capabilities of partner universities by establishing Centers for Psychological Resilience and Wellbeing. These centers will play a pivotal role in providing top-tier psychological support to the university community grappling with the ramifications of war. Moreover, the overarching objective of the project is to heighten awareness surrounding psychological resilience and wellbeing within these academic institutions, while simultaneously bolstering expertise in trauma-informed therapy among students and faculty members.</p>
                        <p style={{ textIndent: '1.25em' }}>We aspire to initiate collaborations with local authorities and volunteers dedicated to aiding those impacted by war. Through this collaboration, we seek to cultivate a new cadre of psychologists equipped with advanced methods and techniques essential for assisting individuals profoundly affected by the scourge of war in attaining equilibrium and happiness.</p>
                    </div>
                } />
        </div>
    );
}

export default UPC;
