import Content from '../components/content';

function Partners() {
    return (
        <div>
            <Content
                title='Partners'
                content={
                    <><div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 ">
                        <div className="flex w-full flex-col text-center m-4 p-4 text-xl justify-center items-center">
                            <a href="https://tnpu.edu.ua/en/" className="flex mt-2 flex-col text-center m-4 p-4 text-xl justify-center items-center">
                                <img className="w-auto h-24" src="/partners-images/TernopilVolodymyrHnatiukNationalPedagogicalUniversity.png" alt="" />
                                <div className="font-primary text-xl ml-2">Ternopil Volodymyr Hnatiuk National Pedagogical University<br/>(Ternopil, Ukraine)</div>
                            </a>
                        </div>
                        <div className="flex w-full flex-col text-center m-4 p-4 text-xl justify-center items-center">
                            <a href="https://kdpu.edu.ua/en" className="flex mt-2 flex-col text-center m-4 p-4 text-xl justify-center items-center">
                                <img className="w-auto h-24" src="/partners-images/KryvyiRihStatePedagogicalUniversity.png" alt="" />
                                <div className="font-primary text-xl ml-2">Kryvyi Rih State Pedagogical University <br/>(Kryvyi Rih, Ukraine)</div>
                            </a>
                        </div>
                        <div className="flex w-full flex-col text-center m-4 p-4 text-xl justify-center items-center">
                            <a href="https://mdpu.org.ua/en/bogdan-khmelnitsky-melitopol-state-pedagogical-university/" className="flex mt-2 flex-col text-center m-4 p-4 text-xl justify-center items-center">
                                <img className="w-auto h-22" src="/partners-images/BogdanKhmelnitskyMelitopolStatePedagogicalUniversity.png" alt="" />
                                <div className="font-primary text-xl ml-2">Bogdan Khmelnitsky Melitopol State Pedagogical University <br/>(Melitopol-Zaporizhzhia, Ukraine)</div>
                            </a>
                        </div>
                        <div className="flex w-full flex-col text-center m-4 p-4 text-xl justify-center items-center">
                            <a href="https://www.kspu.edu/" className="flex mt-2 flex-col text-center m-4 p-4 text-xl justify-center items-center">
                                <img className="w-auto h-24" src="/partners-images/KhersonStateUniversity.png" alt="" />
                                <div className="font-primary text-xl ml-2">Kherson State University<br/>(Kherson-Ivano-Frankivsk, Ukraine)</div>
                            </a>
                        </div>
                        <div className="flex w-full flex-col text-center m-4 p-4 text-xl justify-center items-center">
                            <a href="https://ddpu.edu.ua/index.php/english-version" className="flex mt-2 flex-col text-center m-4 p-4 text-xl justify-center items-center">
                                <img className="w-auto h-24" src="/partners-images/Horlivka.png" alt="" />
                                <div className="font-primary text-xl ml-2">Horlivka Institute for Foreign Languages of Donbas State Pedagogical University <br/>(Bahmut-Dnipro, Ukraine)</div>
                            </a>
                        </div>
                        <div className="flex w-full flex-col text-center m-4 p-4 text-xl justify-center items-center">
                            <a href="https://ut.ee/et/avaleht" className="flex mt-2 flex-col text-center m-4 p-4 text-xl justify-center items-center">
                                <img className="w-auto h-24" src="/partners-images/UniversityofTartu.png" alt="" />
                                <div className="font-primary text-xl ml-2">University of Tartu <br/>(Tartu, Estonia)</div>
                            </a>
                        </div>
                    </div>
                        <div className="grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 justify-center items-center">
                            <div className="flex w-full flex-col text-center m-4 p-4 text-xl justify-center items-center">
                                <a href="https://www.ipu-berlin.de/en/" className="flex flex-col text-center m-4 p-4 text-xl justify-center items-center">
                                    <img className="w-auto h-24" src="/partners-images/InternationalPsychoanalyticUniversityBerlin.png" alt="" />
                                    <div className="font-primary text-xl ml-2">International Psychoanalytic University Berlin GmbH<br/>(Berlin, Germany)</div>
                                </a>
                            </div>
                            <div className="flex w-full flex-col text-center m-4 p-4 text-xl justify-center items-center">
                                <a href="https://www.lu.lv/" className="flex flex-col text-center m-4 p-4 text-xl justify-center items-center">
                                    <img className="w-auto h-24" src="/partners-images/UniversityofLatvia.png" alt="" />
                                    <div className="font-primary text-xl ml-2">University of Latvia <br/>(Riga, Latvia)</div>
                                </a>
                            </div>
                        </div>
                    </>
                } />
        </div>
    );
}

export default Partners;
