import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryWebinar3() {
    const images = [];
    for (let i = 1; i <= 39; i++) {
        images.push({
            original: `/gallery/webinar-3/${i}.png`,
            thumbnail: `/gallery/webinar-3/thumbnails/${i}.png`,
        });
    }

    return (
        <div>
            <Content
                title={`Webinar 3 "Germany's Syllabus Development Experience" (May 7, 2024)`}
                content={<ImageGallery items={images} showPlayButton={false} />}
            />
        </div>
    );
}

export default GalleryWebinar3;
