import { ReactElement } from "react";

interface ContentProps {
    title: string;
    content: ReactElement | string;
}

function Content({ title, content }: ContentProps) {
    return (
        <div className="min-h-screen flex flex-col items-center pt-6 sm:pt-0 bg-white dark:bg-gray-900">
            <div className="w-full sm:max-w-7xl px-6 py-4 dark:bg-gray-800 overflow-hidden sm:rounded-lg">
                <div className="h-100">
                    <h1 className="text-4xl font-primary text-secondary-custom text-center mt-2">
                        <strong>{title}</strong>
                    </h1>
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        {content}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
