import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryWebinar4() {
    const images = [];
    for (let i = 1; i <= 15; i++) {
        images.push({
            original: `/gallery/webinar-4/${i}.png`,
            thumbnail: `/gallery/webinar-4/thumbnails/${i}.png`,
        });
    }

    return (
        <div>
            <Content
                title={`Webinar 4 "Center for Psychological Resilience and Wellbeing" at University of Latvia (May 8, 2024)`}
                content={<ImageGallery items={images} showPlayButton={false} />}
            />
        </div>
    );
}

export default GalleryWebinar4;
