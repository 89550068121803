import Content from '../components/content';

function Contact() {
    return (
        <div>
            <Content
                title='Contacts'
                content={
                    <><div className="grid grid-cols-2 m-2">
                        <div className="flex flex-col border-double rounded-tl-xl rounded-br-xl border-4 border-primary m-2 p-2 text-xl">
                            <div className="text-center">
                                Project manager: <strong>Iryna Zadorozhna</strong>, Vice-Rector<br/>(Research and International Cooperation)
                            </div>
                            <div>
                                <b>E-mail:</b>
                                <p>zadorozhna.iryna@tnpu.edu.ua</p>
                            </div>
                            <div>
                                <b>Phone:</b>
                                <p>+380679508709</p>
                            </div>
                        </div>
                        <div className="flex flex-col border-double rounded-tl-xl rounded-br-xl border-4 border-primary m-2 p-2 text-xl">
                            <div className="text-center">
                                Web-site moderator:  <strong>Iryna Mintii</strong>
                            </div>
                            <div>
                                <br/>
                                <b>E-mail:</b>
                                <p>irina.mintiy@kdpu.edu.ua</p>
                            </div>
                            <div>
                                <b>Phone:</b>
                                <p>+380983516035</p>
                            </div>
                        </div>

                    </div>
                        <div>
                            <style>
                                {`
                            .ck-content p, ul {
                                margin-bottom: 1em;
                                text-align: justify;
                            }
                        `}
                            </style>
                            <div className='text-center'>
                                <strong>GDPR Policy</strong>
                            </div>
                            <p className='text-center'>
                                <strong>Statement of intent</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                BURN project is required to keep and process certain information about its clients, associates and suppliers in accordance with its legal obligations under the General Data Protection Regulation (GDPR). BURN project, from time to time, be required to share personal information about its clients, associates and suppliers. This policy is in place to outline how we comply with the core principles of the GDPR. Organisational methods for keeping data secure are imperative, and Honeybee Psychology believes that it is good practice to keep clear practical policies. This policy complies with the requirements set out in the GDPR, which came into effect on 25 May 2018.
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                The data processor and data controller is: Kathleen Gayton, educational psychologist.
                            </p>
                            <p className='text-center'>
                                <strong>Legal framework</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                This policy has due regard to legislation, including, but not limited to the following:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>The General Data Protection Regulation (GDPR)</li>
                                <li>The Freedom of Information Act 2000</li>
                                <li>The Freedom of Information and Data Protection (Appropriate Limit and Fees) Regulations 2004</li>
                            </ul>
                            <p style={{ textIndent: '1.25em' }}>
                                This policy will also have regard to the following guidance:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>Information Commissioner’s Office (2017) ‘Overview of the General Data Protection Regulation (GDPR)’</li>
                                <li>Information Commissioner’s Office (2017) ‘Preparing for the General Data Protection Regulation (GDPR) 12 steps to take now’</li>
                            </ul>
                            <p className='text-center'>
                                <strong>Applicable data</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                For the purpose of this policy, personal data refers to information that relates to an identifiable, living individual, including information such as an online identifier, such as an IP address. The GDPR applies to both automated personal data and to manual filing systems, where personal data is accessible according to specific criteria, as well as to chronologically ordered data and pseudonymised data, e.g. key-coded. Sensitive personal data is referred to in the GDPR as ‘special categories of personal data’, which are broadly the same as those in the Data Protection Act (DPA) 1998. These specifically include the processing of genetic data, biometric data and data concerning health matters.
                            </p>
                            <p className='text-center'>
                                <strong>Principles</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                In accordance with the requirements outlined in the GDPR, personal data will be:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>Processed lawfully, fairly and in a transparent manner in relation to individuals.</li>
                                <li>Collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes; further processing for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes shall not be considered to be incompatible with the initial purposes.</li>
                                <li>Adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed.</li>
                                <li>Accurate and, where necessary, kept up-to-date; every reasonable step must be taken to ensure that personal data that are inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay.</li>
                                <li>Kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed; personal data may be stored for longer periods, insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes, subject to implementation of the appropriate technical and organisational measures required by the GDPR in order to safeguard the rights and freedoms of individuals.</li>
                                <li>Processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures. The GDPR also requires that “the controller shall be responsible for, and able to demonstrate, compliance with the principles”.</li>
                            </ul>
                            <p className='text-center'>
                                <strong>Accountability</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                BURN project will implement appropriate technical and organisational measures to demonstrate that data is processed in line with the principles set out in the GDPR and will provide clear and transparent privacy policies.
                                Records of activities relating to higher risk processing will be maintained, such as the processing of special categories data (e.g. SEN information)
                                Internal records of processing activities will include the following:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>Name and details of the organisation</li>
                                <li>Purpose(s) of the processing</li>
                                <li>Description of the categories of individuals and personal data</li>
                                <li>Retention schedules</li>
                                <li>Categories of recipients of personal data</li>
                                <li>Description of technical and organisational security measures</li>
                                <li>Details of transfers to third countries, including documentation of the transfer mechanism safeguards in place</li>
                            </ul>
                            <p style={{ textIndent: '1.25em' }}>
                                BURN project will implement measures that meet the principles of data protection by design and data protection by default, such as:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>Data minimisation.</li>
                                <li>Pseudonymisation.</li>
                                <li>Transparency.</li>
                                <li>Continuously creating and improving security features. Data protection impact assessments will be used, where appropriate.</li>
                            </ul>
                            <p className='text-center'>
                                <strong>Lawful processing</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                The legal basis for processing data will be identified and documented prior to data being processed. Under the GDPR, data will be lawfully processed under the following conditions:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>The consent of the data subject has been obtained.</li>
                                <li>Processing is necessary for compliance with a legal obligation.</li>
                            </ul>
                            <p style={{ textIndent: '1.25em' }}>
                                The processing is necessary for the contract with clients to be fulfilled.
                                The processing is necessary for our legitimate interests of those of a third party.
                                Sensitive data will only be processed under the following conditions:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>Explicit consent of the data subject, unless reliance on consent is prohibited by EU or Member State law.</li>
                                <li>Processing relates to personal data manifestly made public by the data subject.</li>
                                <li>Processing is necessary for: Carrying out obligations under employment, social security or social protection law, or a collective agreement, protecting the vital interests of a data subject or another individual where the data subject is physically or legally incapable of giving consent, the establishment, exercise or defence of legal claims or where courts are acting in their judicial capacity, reasons of substantial public interest on the basis of Union or Member State law which is proportionate to the aim pursued and which contains appropriate safeguards, reasons of public interest in the area of public health, such as protecting against serious cross-border threats to health.</li>
                            </ul>
                            <p className='text-center'>
                                <strong>Consent</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                Signed consent is obtained from clients, who are required to sign the Honeybee Psychology terms and conditions agreement. In the case of children, signed consent is required from their parent or legal guardian. Commissioning schools are also required to sign a terms and conditions document, which indicates consent. Consent can be withdrawn by the individual at any time.
                            </p>
                            <p className='text-center'>
                                <strong>The right to be informed</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                The privacy notice supplied to individuals in regards to the processing of their personal data will be written in clear, plain language which is concise, transparent, easily accessible and free of charge. This is available on the BURN project website. If services are offered directly to a child, BURN project will ensure that the privacy notice is written in a clear, plain manner that the child will understand. In relation to data obtained both directly from the data subject and not obtained directly from the data subject, the following information will be supplied within the privacy notice:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>The identity and contact details of the controller.</li>
                                <li>The purpose of, and the legal basis for, processing the data.</li>
                                <li>The legitimate interests of the controller or third party.</li>
                                <li>Any recipient or categories of recipients of the personal data.</li>
                                <li>The retention period of criteria used to determine the retention period.</li>
                                <li>The existence of the data subject’s rights, how to lodge a complaint with a supervisory authority.</li>
                            </ul>
                            <p className='text-center'>
                                <strong>The right of access</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                Individuals have the right to obtain confirmation that their data is being processed. Individuals have the right to submit a subject access request (SAR) to gain access to their personal data in order to verify the lawfulness of the processing. BURN project will verify the identity of the person making the request before any information is supplied. A copy of the information will be supplied to the individual free of charge; however, BURN project may impose a ‘reasonable fee’ to comply with requests for further copies of the same information.
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                Where a SAR has been made electronically, the information will be provided in a commonly used electronic format. Where a request is manifestly unfounded, excessive or repetitive, a reasonable fee will be charged. All fees will be based on the administrative cost of providing the information. All requests will be responded to without delay and at the latest, within one month of receipt. In the event of numerous or complex requests, the period of compliance will be extended by a further two months. The individual will be informed of this extension, and will receive an explanation of why the extension is necessary, within one month of the receipt of the request. Where a request is manifestly unfounded or excessive, BURN project holds the right to refuse to respond to the request. The individual will be informed of this decision and the reasoning behind it, as well as their right to complain to the supervisory authority and to a judicial remedy, within one month of the refusal. In the event that a large quantity of information is being processed about an individual, BURN project will ask the individual to specify the information the request is in relation to.
                            </p>
                            <p className='text-center'>
                                <strong>The right to rectification</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                Individuals are entitled to have any inaccurate or incomplete personal data rectified. Where the personal data in question has been disclosed to third parties, BURN project will inform them of the rectification where possible. Where appropriate, BURN project will inform the individual about the third parties that the data has been disclosed to. Requests for rectification will be responded to within one month; this will be extended by two months where the request for rectification is complex. Where no action is being taken in response to a request for rectification, BURN project will explain the reason for this to the individual, and will inform them of their right to complain to the supervisory authority and to a judicial remedy.
                            </p>
                            <p className='text-center'>
                                <strong>The right to erasure</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                Individuals hold the right to request the deletion or removal of personal data where there is no compelling reason for its continued processing. Individuals have the right to erasure in the following circumstances:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>Where the personal data is no longer necessary in relation to the purpose for which it was originally collected/processed</li>
                                <li>When the individual withdraws their consent</li>
                                <li>When the individual objects to the processing and there is no overriding legitimate interest for continuing the processing</li>
                                <li>The personal data was unlawfully processed</li>
                                <li>The personal data is required to be erased in order to comply with a legal obligation</li>
                                <li>The personal data is processed in relation to the offer of information society services to a child</li>
                            </ul>
                            <p style={{ textIndent: '1.25em' }}>
                                BURN project has the right to refuse a request for erasure where the personal data is being processed for the following reasons:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>To exercise the right of freedom of expression and information</li>
                                <li>To comply with a legal obligation for the performance of a public interest task or exercise of official authority</li>
                                <li>For public health purposes in the public interest</li>
                                <li>For archiving purposes in the public interest, scientific research, historical research or statistical purposes</li>
                                <li>The exercise or defence of legal claims</li>
                            </ul>
                            <p style={{ textIndent: '1.25em' }}>
                                As a child may not fully understand the risks involved in the processing of data when consent is obtained, special attention will be given to existing situations where a child has given consent to processing and they later request erasure of the data, regardless of age at the time of the request.
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                Where personal data has been disclosed to third parties, they will be informed about the erasure of the personal data, unless it is impossible or involves disproportionate effort to do so. Where personal data has been made public within an online environment, BURN project will inform other organisations who process the personal data to erase links to and copies of the personal data in question.
                            </p>
                            <p className='text-center'>
                                <strong>The right to restrict processing</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                Individuals have the right to block or suppress BURN project’s processing of personal data. In the event that processing is restricted, BURN project will store the personal data, but not further process it, guaranteeing that just enough information about the individual has been retained to ensure that the restriction is respected in future. BURN project will restrict the processing of personal data in the following circumstances:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>Where an individual contests the accuracy of the personal data, processing will be restricted until BURN project has verified the accuracy of the data</li>
                                <li>Where an individual has objected to the processing and BURN project is considering whether their legitimate grounds override those of the individual</li>
                                <li>Where processing is unlawful and the individual opposes erasure and requests restriction instead</li>
                                <li>Where BURN project no longer needs the personal data but the individual requires the data to establish, exercise or defend a legal claim</li>
                            </ul>
                            <p style={{ textIndent: '1.25em' }}>
                                If the personal data in question has been disclosed to third parties, BURN project will inform them about the restriction on the processing of the personal data, unless it is impossible or involves disproportionate effort to do so. BURN project will inform individuals when a restriction on processing has been lifted.
                            </p>
                            <p className='text-center'>
                                <strong>The right to data portability</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                Individuals have the right to obtain and reuse their personal data for their own purposes across different services. Personal data can be easily moved, copied or transferred from one IT environment to another in a safe and secure manner, without hindrance to usability. The right to data portability only applies in the following cases:
                            </p>
                            <ul style={{ marginInlineStart: '1.25em' }}>
                                <li>To personal data that an individual has provided to a controller</li>
                                <li>Where the processing is based on the individual’s consent or for the performance of a contract</li>
                            </ul>
                            <p style={{ textIndent: '1.25em' }}>
                                Personal data will be provided in a structured, commonly used and machine-readable form. BURN project will provide the information free of charge. Where feasible, data will be transmitted directly to another organisation at the request of the individual. Honeybee Psychology is not required to adopt or maintain processing systems which are technically compatible with other organisations. In the event that the personal data concerns more than one individual, BURN project will consider whether providing the information would prejudice the rights of any other individual. BURN project will respond to any requests for portability within one month. Where the request is complex, or a number of requests have been received, the time frame can be extended by two months, ensuring that the individual is informed of the extension and the reasoning behind it within one month of the receipt of the request. Where no action is being taken in response to a request, BURN project will, without delay and at the latest within one month, explain to the individual the reason for this and will inform them of their right to complain to the supervisory authority and to a judicial remedy.
                            </p>
                            <p className='text-center'>
                                <strong>The right to object</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                BURN project will inform individuals of their right to object at the first point of communication, and this information will be outlined in the privacy notice and explicitly brought to the attention of the data subject, ensuring that it is presented clearly and separately from any other information.
                            </p>
                            <p className='text-center'>
                                <strong>Privacy by design and privacy impact assessments</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                BURN project will act in accordance with the GDPR by adopting a privacy by design approach and implementing technical and organisational measures which demonstrate how we have considered and integrated data protection into processing activities. Data protection impact assessments (DPIAs) will be used to identify the most effective method of complying with our data protection obligations and meeting individuals’ expectations of privacy.
                            </p>
                            <p className='text-center'>
                                <strong>Data breaches</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                The term ‘personal data breach’ refers to a breach of security which has led to the destruction, loss, alteration, unauthorised disclosure of, or access to, personal data. Iryna Myntii is aware of what constitutes a data breach. Where a breach is likely to result in a risk to the rights and freedoms of individuals, the relevant supervisory authority will be informed. All notifiable breaches will be reported to the relevant supervisory authority within 72 hours of BURN project becoming aware of it. The risk of the breach having a detrimental effect on the individual, and the need to notify the relevant supervisory authority, will be assessed on a case-by-case basis. In the event that a breach is likely to result in a high risk to the rights and freedoms of an individual, BURN project will notify those concerned directly. A ‘high risk’ breach means that the threshold for notifying the individual is higher than that for notifying the relevant supervisory authority.
                            </p>
                            <p className='text-center'>
                                <strong>Data security</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                Confidential paper records will be kept in a locked filing cabinet, drawer or safe, with restricted access. When travelling, these will be kept in the boot of a locked car. Confidential paper records will not be left unattended or in clear view anywhere with general access. Digital data is coded, encrypted or password-protected, both on a local hard drive and on a network drive that is regularly backed up off-site. Where data is saved on removable storage or a portable device, the device will be kept in a locked filing cabinet, drawer or safe when not in use. Memory sticks will not be used to hold personal information unless they are password-protected and fully encrypted. All electronic devices are password-protected to protect the information on the device in case of theft. Where reasonable, identifying information such as names and addresses will not be disclosed in emails. Reports will be sent out by password protected email (in pdf format) or as a paper document, by post in an envelope marked confidential.
                            </p>
                            <p className='text-center'>
                                <strong>Data retention</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                Data will not be kept for longer than is necessary. Unrequired data will be deleted as soon as practicable. Reports are retained in electronic format for four years, after which they are deleted. Paper documents such as test sheets, questionnaires and notes are shredded once the report is issued. It is the responsibility of clients to keep their report safe.
                            </p>
                            <p className='text-center'>
                                <strong>DBS data</strong>
                            </p>
                            <p style={{ textIndent: '1.25em' }}>
                                All data provided by the DBS will be handled in line with data protection legislation; this includes electronic communication. Data provided by the DBS will never be duplicated. Copies of DBS certificates will be verified but will not be held on file.
                            </p>
                        </div></>
                } />
        </div>

    );
}

export default Contact;
