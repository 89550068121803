import Content from '../components/content';

function FAQ() {
    return (
        <div>
            <Content
                title='FAQ'
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, ul {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>
                        {/* <p className='text-center'>
                            <strong>Statement of intent</strong>
                        </p>
                        <p style={{ textIndent: '1.25em' }}>
                            BURN project is required to keep and process certain information about its clients, associates and suppliers in accordance with its legal obligations under the General Data Protection Regulation (GDPR). BURN project, from time to time, be required to share personal information about its clients, associates and suppliers. This policy is in place to outline how we comply with the core principles of the GDPR. Organisational methods for keeping data secure are imperative, and Honeybee Psychology believes that it is good practice to keep clear practical policies. This policy complies with the requirements set out in the GDPR, which came into effect on 25 May 2018.
                        </p> */}
                    </div>
                } />
        </div>
    );
}

export default FAQ;
