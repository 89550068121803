import Content from '../../components/content';

function Webinar89() {
    return (
        <div>
            <Content
                title={`Webinars 8-9 "Psychological Support Center" at the University of Latvia and "Experience in teaching humanitarian courses" at the University of Tartu (May 22-23, 2024)`}
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, img {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>

                        <p style={{ textIndent: '1.25em' }}>Psychological Support Center (PSC) of the Department of Psychology – How We Operate and Achieve Our Goals at the University of Latvia
                            Presented by Dr. Laura Pirsko, Head of PSC and Director of the Professional Master Program in Psychology. Laura Pirsko explained the structure of the psychological center, which is part of the Faculty of Psychology at the University of Latvia.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-89/4.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>This center has been operating within the Faculty of Psychology for several years and is responsible for providing psychological services at the master's level. During their studies, master's students work as employees of this center, undergoing internships as consultants and receiving supervision. It is particularly important to emphasize that the main goals of the psychological center are to provide a foundation for the implementation of psychological research for the master's program, as well as to offer psychological assistance and counseling, including CBT (cognitive-behavioral therapy), to clients. They have a waiting list of clients who can receive help at the faculty and can also undergo diagnostic assessment there.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-89/6.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>The psychological center serves as a bridge between the community, specifically the city of Riga, and the university, as it facilitates cooperation with stakeholders in providing not only educational but also psychotherapeutic and psychodiagnostic services. The main goals include offering internships at this center, serving as a research base, and obtaining project grants. The center also co-organizes educational events and promotes psychology within the community alongside the faculty.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-89/7.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>Clients can access therapy provided by master's students under the supervision of professors. Each year, between 2 to 10 professors oversee this supervision. Information about available services is disseminated through websites and social media, allowing people to fill out an application form, which creates a waiting list. Currently, there are 189 people on the waiting list for counseling and diagnostics, and 262 individuals are currently undergoing therapy. Each student typically supervises about two clients per year during their third and fourth semesters in the master's program, meeting with them for at least two sessions per week, sometimes two to four sessions. Additionally, students receive weekly supervision from a faculty professor to discuss complex cases. What are the typical requests at the center? Specifically, 65% of the clients are adults, and up to 35% are children. This proportion is similar to that found in private practice. Among these adults, 25% are students. The main issues they seek help for include emotional difficulties such as depression or anxiety, as well as adaptation problems and learning difficulties. In terms of gender distribution, 68% of the clients are women, and 32% are men. Diagnostic assessments account for 30% of the center's services, 65% are individual consultations, and 5% are group sessions.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-89/10.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>A typical consultant at the center is a second-year master's student specializing in clinical psychology or health psychology. As mentioned, they manage two to four clients or client sessions per week. Students regularly participate in intensive group supervision with a professor, or individual supervision when necessary for complex cases. Together with the professor, they analyze these cases, forming a robust practical training program based on cognitive-behavioral therapy and schema therapy. This program helps them develop essential skills such as counseling techniques, case management, and diagnostic skills, effectively bridging the gap between theory and practice.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-89/b1.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>On May 22, an insightful webinar focused on using Moodle to support online learning, specifically within the context of a humanitarian course. This session, led by Dr. Olga Burdakova, provided comprehensive guidance on effectively utilizing Moodle to enhance course delivery and student engagement.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-89/12.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>Webinar Highlights:</p>
                        <ol>
                            <li>Course Structure in Moodle: Designing the Course "Cover", Mandatory and Optional Elements, Target Audience and Objectives, Permanent Sections, Literature Lists, Admission and Assessment Criteria - tips were shared on clearly defining the conditions for exams or tests, as well as outlining current and final assessments.</li>
                            <li>
                                2. Planning Exercises in Moodle:
                                <ul>
                                    <li>Learning Outcomes as a Foundation. The webinar demonstrated how to develop exercises based on specific learning outcomes.</li>
                                    <li>Types of Exercises. Distinctions were made between training (preparatory) and control exercises to optimize student learning.</li>
                                </ul>
                            </li>
                            <li>
                                Assessing the Quality of a Moodle Course:
                                <ul>
                                    <li>Analysis Criteria - key criteria for evaluating and ensuring the quality of Moodle courses were discussed.</li>
                                </ul>
                            </li>
                        </ol>
                        <p style={{ textIndent: '1.25em' }}>The webinar was designed for educators looking to deepen their understanding of Moodle and improve their online course offerings. Attendees left with enhanced teaching strategies and new methods to engage their students more effectively.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-89/13.png" alt="burnLogo" />
                        </div>

                        <ul>
                            <li>Individual Counseling. Personalized sessions to address specific concerns.</li>
                            <li>Group Counseling. Sessions where individuals with similar challenges can share experiences and learn from each other.</li>
                            <li>Workshops and Seminars. Educational sessions on various mental health topics to equip participants with coping strategies.</li>
                            <li>Crisis Intervention. Immediate support for those experiencing a mental health crisis.</li>
                            <li>Support for International Students. Assistance for international students adjusting to life in Germany.</li>
                        </ul>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-89/5.png" alt="burnLogo" />
                        </div>
                        
                        <p style={{ textIndent: '1.25em' }}>The seminar also discussed the Mental Health Project at Freie Universität Berlin, a comprehensive initiative to promote mental health awareness and provide support. Key components of the project include: 1) Workshops and information sessions on mental health and stress management. 2) Plans to extend the project for two additional years to enhance mental health management, introduce longer-term counseling, provide staff supervision, and expand workshop offerings.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-89/9.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>This initiative underscores the university's dedication to fostering a supportive and inclusive environment that promotes mental well-being. If you are a student or staff member of Freie Universität Berlin and need psychological support, further information is available on the university's website. Additional resources: <a target="_blank" rel="noopener noreferrer" style={{color: "blue", textDecorationLine: "underline"}} href="https://lnkd.in/eqM3EGXj">Website of the Psychological Counseling Service of Freie Universität Berlin</a></p>
                        <p style={{ textIndent: '1.25em' }}>The Psychological Counseling Service at Freie Universität Berlin is a valuable resource, providing a wide array of support to help individuals manage their mental health effectively.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-89/10.png" alt="burnLogo" />
                        </div>
                    </div>
                } />
        </div>
    );
}

export default Webinar89;
