import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryWebinar10() {
    const images = [];
    for (let i = 1; i <= 34; i++) {
        images.push({
            original: `/gallery/webinar-10/${i}.png`,
            thumbnail: `/gallery/webinar-10/thumbnails/${i}.png`,
        });
    }

    return (
        <div>
            <Content
                title={`Webinar 10 "Course Design and Evaluation" at Ternopil Volodymyr Hnatiuk National Pedagogical University (May 27, 2024)`}
                content={<ImageGallery items={images} showPlayButton={false} />}
            />
        </div>
    );
}

export default GalleryWebinar10;
