import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryWebinar11() {
    const images = [];
    for (let i = 1; i <= 21; i++) {
        images.push({
            original: `/gallery/webinar-11/${i}.png`,
            thumbnail: `/gallery/webinar-11/thumbnails/${i}.png`,
        });
    }

    return (
        <div>
            <Content
                title={`Webinar 11 "Freie Universität Berlin's Psychological Counseling Service" (May 29, 2024)`}
                content={<ImageGallery items={images} showPlayButton={false} />}
            />
        </div>
    );
}

export default GalleryWebinar11;
