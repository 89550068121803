import Content from '../components/content';

function ErrorPage() {
    return (
        <div>
            <Content
                title='Oops! Page not found'
                content={
                    <div className="text-center">
                        <p className="mt-3">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                        <a href="/" className="text-blue-600 hover:underline mt-5">
                            Go back to the homepage
                        </a>
                    </div>
                } />
        </div>
    );
}

export default ErrorPage;
