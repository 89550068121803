import Content from '../components/content';

function MeetTeam() {
    return (
        <div>
            <Content
                title='Meet the team'
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, ul {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                                .ck-content a {
                                    color: blue;
                                    text-decoration: underline;
                                }
                            `}
                        </style>
                        <p>I. Ternopil Volodymyr Hnatiuk National Pedagogical University</p>
                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>Manager: Iryna Zadorozhna.</li>
                           
                        </ul>

                        <p>II. <a href="https://kdpu.edu.ua/praktychnoi-psykholohii/zahalna-informatsiia/mizhnarodni-proekty-ta-spivrobitnytstvo/erasmus-2.html">Kryvyi Rih State Pedagogical University</a></p>
                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>Manager: <a href="https://www.researchgate.net/profile/Bondar-Kateryna">Kateryna Bondar.</a></li>
                            
                        </ul>

                        <p>III. Bogdan Khmelnitsky Melitopol State Pedagogical University</p>
                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>Manager: <a href="https://www.researchgate.net/profile/Natalia_Falko">Natalia Falko.</a></li>
                           
                        </ul>

                        <p>IV. Kherson State University</p>
                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>Manager: <a href="https://www.researchgate.net/profile/Ihor-Popovych">Ihor Popovych.</a></li>
                           
                        </ul>

                        <p>V. Horlivka Institute for Foreign Languages of Donbas State Pedagogical University</p>
                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>Manager: <a href="https://www.researchgate.net/profile/Tetiana-Borozentseva">Tetіana Borozentseva.</a></li>
                           
                        </ul>

                        <p>VI. University of Tartu</p>
                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>Manager: Oleksandra Golovko.</li>
                           
                        </ul>

                        <p>VII. International Psychoanalytic University Berlin GmbH</p>
                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>Manager: Carmen Scheer.</li>
                        </ul>

                        <p>VIII. University of Latvia</p>
                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>Manager: Ieva Stokenberga.</li>
                        </ul>
                    </div>

                } />
        </div>
    );
}

export default MeetTeam;
