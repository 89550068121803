import Content from '../../components/content';

function Webinar10() {
    return (
        <div>
            <Content
                title={`Webinar 10 "Course Design and Evaluation" at Ternopil Volodymyr Hnatiuk National Pedagogical University (May 27, 2024)`}
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, img {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>

                        <p style={{ textIndent: '1.25em' }}>Closing Webinar of the "Experience Sharing Webinars: Course Design Principles in the European Union" Series by Ternopil National University
                            On May 27, Ternopil National University named after Volodymyr Hnatyuk held the closing webinar of the "Course Design and Evaluation" series. This event marked the culmination of the Erasmus+ project MultiEd, which aimed to enhance foreign language training in universities, particularly English language instruction.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-10/6.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>The project, based on the CLIL (Content and Language Integrated Learning) methodology, included both external and internal mobility within Ukraine. Vice-Rector and project manager of BURN project Prof. Ірина Задорожна highlighted the project's structure, goals, activities, and the process of course transformation and development.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-10/5.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>Participants were introduced to various syllabus examples, focusing on both English language teaching and technical disciplines taught in English. Iryna Zadorozhna shared her own syllabus development experience, detailing the creation of goals, competencies, and the distribution of responsibilities among the development team.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-10/9.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>Key components of the presented syllabus structure included:</p>
                        <ul>
                            <li>Course Information. Discipline name, course code, field of study, and form of study (full-time, part-time, distance learning).</li>
                            <li>Keywords. Main topics covered in the course.</li>
                            <li>Competencies. General and specific skills and knowledge to be developed.</li>
                            <li>Learning Outcomes. Measurable descriptions of student achievements upon course completion.</li>
                            <li>Correlation Matrix. Alignment of course learning outcomes with the overall curricula.</li>
                            <li>Course Matrix. Course structure, including hours, content blocks, and competency mapping.</li>
                            <li>Assessment Components. Methods for evaluating student learning, including independent and practical work assessments.</li>
                            <li>Expert Evaluation. Compliance with European Higher Education Area (EHEA) standards as evaluated by Erasmus+ Office Ukraine.</li>
                            <li>Teaching Quality Assessment. Tools and methods for assessing teaching quality.</li>
                            <li>Literature and Sources. Guidelines for using literature and developing original sources in line with EU standards.</li>
                        </ul>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-10/b6.png" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>This webinar provided participants with practical knowledge and skills for designing effective courses, ensuring they meet EHEA standards. The MultiEd project has successfully equipped educators with the tools necessary for enhancing language training and course development.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-10/14.png" alt="burnLogo" />
                        </div>
                    </div>
                } />
        </div>
    );
}

export default Webinar10;
