import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryEstonianExperience() {
    const images = [];
    for (let i = 1; i <= 5; i++) {
        images.push({
            original: `/gallery/estonian-experience/${i}.jpg`,
            thumbnail: `/gallery/estonian-experience/thumbnails/${i}.jpg`,
        });
    }

    return (
        <div>
            <Content
                title='Webinar 1 "Estonian experience in syllabus development" at University of Tartu (April 22, 2024)'
                content={
                    <ImageGallery items={images} showPlayButton={false} />
                } />
        </div>
    );
}

export default GalleryEstonianExperience;
