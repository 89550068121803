import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryWebinar5() {
    const images = [];
    for (let i = 1; i <= 29; i++) {
        images.push({
            original: `/gallery/webinar-5/${i}.png`,
            thumbnail: `/gallery/webinar-5/thumbnails/${i}.png`,
        });
    }

    return (
        <div>
            <Content
                title={`Webinar  5 "Counselling Centre" at University of Tartu (May 13, 2024)`}
                content={<ImageGallery items={images} showPlayButton={false} />}
            />
        </div>
    );
}

export default GalleryWebinar5;
