import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryWebinar14() {
    const images = [];
    for (let i = 1; i <= 8; i++) {
        images.push({
            original: `/gallery/webinar-14/${i}.jpg`,
            thumbnail: `/gallery/webinar-14/thumbnails/${i}.jpg`,
        });
    }

    return (
        <div>
            <Content
                title={`Study Visit on Psychological Resilience and Training in Latvia (September 23-27, 2024)`}
                content={<ImageGallery items={images} showPlayButton={false} />}
            />
        </div>
    );
}

export default GalleryWebinar14;
