import Content from '../components/content';

function TrainingProgram() {
    return (
        <div>
            <Content
                title='Materials in developing'
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, ul, table {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>
                        {/* <p style={{ textIndent: '1.25em' }}>
                            1. Online webinars “Course design principles in the EU” (6 webinars, 30 UA participants).<br/>
                            Outcomes - partners will get acquainted with the general EU principles in course design and national/institutional differences.
                        </p>

                        <p style={{ textIndent: '1.25em' }}>
                            2. Course design and psychologist training in Latvia 18 people (3 participants from Ukrainian HEIs, 1 from EU partners).<br/>
                            Outcomes - psychologist training, practices of teaching psychological courses for different target groups, trauma-centred psychotherapy techniques, expressive arts with trauma, strategies for working with refugees, working with veterans, psychosocial rehabilitation methods, and crisis counselling, visit teams will share the learned EU experience at their universities.
                        </p>

                        <p style={{ textIndent: '1.25em' }}>
                            3. Online experience sharing webinars on university psychological services (6 webinars, 30 UA participants).<br/>
                            Outcomes - presenting case models of successful trauma-informed support psychological integration of refugee students into the educational process in EU University Psychological Service; structure and functions of counselling services of psychosocial trauma-sensitive approach in acute crises of academic staff and students; models of adaptation and trauma-informed mentoring for students affected by war, etc. EU partners explain the content of their statutes and share the documents with UA partners for analysis.
                        </p>

                        <p style={{ textIndent: '1.25em' }}>
                            4. Study visit to Estonia on the psychological service work organization (18 participants (3 from Ukrainian HEIs and 1 from EU partners)).<br/>
                            Outcomes - Presentation of cases of university services in Psychological Support Centers: crisis assistance for youth, mentoring for refugee students affected by war, supporting inclusive students, supporting the well-being of academic staff (scientists at risk) and students, and student veterans’ concerns. In return, the study visit teams will share the learned EU experience at their universities.
                        </p>

                        <p style={{ textIndent: '1.25em' }}>
                            5. Online Roundtable Discussions “Psychological Service Centers: Areas of Work and Perspectives” (100 participants total).<br/>
                            Outcomes - share general ideas on strategy for the psychological centre and its work to help students and staff, opportunities for the universities to find the best niches for their Centers that will help with the Statute work later.
                        </p>

                        <p style={{ textIndent: '1.25em' }}>
                            6. Training course for Center workers ”How to Keep the Professional Level High” Duration: 30h (12 hours of online learning and 18 hours of teamwork).<br/>
                            Outcomes - presentation of  “Ideal Psychological Support Center”. This work will become the basis for the prospective Statute.
                        </p>

                        <p style={{ textIndent: '1.25em' }}>
                            7. Study visit to Germany - 18 participants (3 from Ukrainian HEIs and 1 from EU partners).<br/>
                            Outcomes -  professional development of the psychological centre team, acquisition of skills of psychological ensuring high-quality teaching (sensitivity training) with students affected by war (training, coaching, and supervision for academic staff), crisis counselling at University Psychology Support Centers. The differences in response to the same traumatic event: resilience and PTSD. Burnout in War Zone educators and psychological rehabilitation.
                        </p>

                        <p style={{ textIndent: '1.25em' }}>
                            8. Autumn/Spring School for Center psychologists (Trainers: 6 (2 trainers from EU partners) Participants: 25 (5 per UA partner)).<br/>
                            Outcomes - the school will boost the capacity of the psychology resilience centre and help UA partners establish firm support practices.

                        </p>

                        <p style={{ textIndent: '1.25em' }}>
                            9. Local Summer Schools "Principles of Trauma-Informed Care for Mentally Healthier Community" (Number of participants: 100 (25 for each school) Duration: 5 days).<br/>
                            Outcomes - workshops on sensitivity training for civic society activists working with people affected by war. The summer school program includes the task of forming algorithms for primary psychosocial assistance; emotional well-being and resilience, distress tolerance group, mindfulness workshop, mindfulness-based art therapy, Interventions for helping those who have fled war, building strategies for safety, support resilience, and work with multiple levels of loss, after extreme and traumatic experiences
                        </p>

                        <p style={{ textIndent: '1.25em' }}>
                            10. Webinar on the work of Centers for Psychological Resilience and Wellbeing (Number of participants: 100 Duration: 4 h).<br/>
                            Outcomes - sensitivity training for academic staff working with students affected by war; primary psychosocial assistance in the adaptation of psychologically traumatised students for teachers (mentors of academic groups); formation of their educational perspective and future career options; adaptation and mentoring for first-year students affected by war; psychological counselling for students and professors veterans. It involves creating a supportive and non-judgmental university culture, using trauma-sensitive teaching strategies, and providing resources for students and staff to address the effects of trauma. Trauma-informed education promotes healing and resilience in students and creates a more equitable and effective learning environment.
                        </p>

                        <p style={{ textIndent: '1.25em' }}>
                            11. International hybrid conference “Trauma-Sensitive approach at Universities” (The number of participants: 200 Duration; 2 days).<br/>
                            Outcomes - a plenary session and a range of workshops on the modern methods of:
                        </p>

                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>crisis online counselling and support groups that may be of help to trauma survivors, including healing from sexual abuse;</li>
                            <li>foundations of Trauma, Trauma-Centered psychotherapy techniques;</li>
                            <li>building resilience with students who have experienced trauma,</li>
                            <li>traumatic bereavement,</li>
                            <li>working with refugees and survivors of War,</li>
                            <li>trauma Informed service systems,</li>
                            <li>secondary traumatic stress,</li>
                            <li>compassion fatigue and resilience,</li>
                            <li>trauma-sensitive practice,</li>
                            <li>Integrative Trauma Treatment: promising frameworks and approaches,</li>
                            <li>Drama therapy with Trauma.</li>
                        </ul>

                        <div className="text-xl ck-content font-secondary-custom mt-6">
                            <p className='text-center'>Webinars in WP 2 (Latvia lead partner)</p>
                            <table className="table-auto w-full border-collapse border border-gray-300">
                                <thead>
                                    <tr className="border border-gray-300">
                                        <th className="border border-gray-300 p-2">#</th>
                                        <th className="border border-gray-300 p-2">Country</th>
                                        <th className="border border-gray-300 p-2">Speaker</th>
                                        <th className="border border-gray-300 p-2">Topic</th>
                                        <th className="border border-gray-300 p-2">Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">1</td>
                                        <td className="border border-gray-300 p-2">Latvia</td>
                                        <td className="border border-gray-300 p-2">Anda Upmane</td>
                                        <td className="border border-gray-300 p-2">Latvian experience in syllabus development</td>
                                        <td className="border border-gray-300 p-2">April 29, 14.00</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">2</td>
                                        <td className="border border-gray-300 p-2">Estonia</td>
                                        <td className="border border-gray-300 p-2">Anu Leuska</td>
                                        <td className="border border-gray-300 p-2">Estonian experience in syllabus development (elective course for school and kindergarten teachers "Introduction to crisis intervention")</td>
                                        <td className="border border-gray-300 p-2">April 22, 14.00</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">3</td>
                                        <td className="border border-gray-300 p-2">Germany</td>
                                        <td className="border border-gray-300 p-2">Lars Kuchinke & Phil Langer</td>
                                        <td className="border border-gray-300 p-2">German experience in syllabus development</td>
                                        <td className="border border-gray-300 p-2">May 7th 15.00</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">4</td>
                                        <td className="border border-gray-300 p-2">Estonia</td>
                                        <td className="border border-gray-300 p-2">Olga Burdakova</td>
                                        <td className="border border-gray-300 p-2">Moodle support for online learning (on an example of a humanitarian course)</td>
                                        <td className="border border-gray-300 p-2">May 22, 15.00</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">5</td>
                                        <td className="border border-gray-300 p-2">Germany / Estonia</td>
                                        <td className="border border-gray-300 p-2">Kaire Uiboleht (UT)</td>
                                        <td className="border border-gray-300 p-2">Course quality and evaluation</td>
                                        <td className="border border-gray-300 p-2">May 23, 28, 29, or 30?</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">5a</td>
                                        <td className="border border-gray-300 p-2">Germany</td>
                                        <td className="border border-gray-300 p-2">Wiebke Stelling & Christine Möllers</td>
                                        <td className="border border-gray-300 p-2">Course evaluation at IPU Berlin</td>
                                        <td className="border border-gray-300 p-2">May 14th 15.00</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">6</td>
                                        <td className="border border-gray-300 p-2">Ukraine</td>
                                        <td className="border border-gray-300 p-2">National Erasmus Office</td>
                                        <td className="border border-gray-300 p-2">Course development experience sharing (MultiEd project)</td>
                                        <td className="border border-gray-300 p-2"></td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">7</td>
                                        <td className="border border-gray-300 p-2">Ternopil</td>
                                        <td className="border border-gray-300 p-2"></td>
                                        <td className="border border-gray-300 p-2">Course development experience sharing (MultiEd project)</td>
                                        <td className="border border-gray-300 p-2"></td>
                                    </tr>
                                </tbody>
                            </table>

                            <p className='text-center'>Webinars in WP 3 (Estonia lead partner)</p>

                            <table className="table-auto w-full border-collapse border border-gray-300">
                                <thead>
                                    <tr className="border border-gray-300">
                                        <th className="border border-gray-300 p-2">#</th>
                                        <th className="border border-gray-300 p-2">Country</th>
                                        <th className="border border-gray-300 p-2">Speaker</th>
                                        <th className="border border-gray-300 p-2">Topic</th>
                                        <th className="border border-gray-300 p-2">Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">1</td>
                                        <td className="border border-gray-300 p-2">Latvia</td>
                                        <td className="border border-gray-300 p-2">Dace Siliņa</td>
                                        <td className="border border-gray-300 p-2">University of Latvia Career Centre: Sharing experience on providing support for students</td>
                                        <td className="border border-gray-300 p-2">May 8 14.00</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">2</td>
                                        <td className="border border-gray-300 p-2">Latvia</td>
                                        <td className="border border-gray-300 p-2">Laura Pirsko</td>
                                        <td className="border border-gray-300 p-2">University of Latvia Psychological support centre of the Department of Psychology – how we operate and achieve our goals</td>
                                        <td className="border border-gray-300 p-2">May 23, 14.00</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">3</td>
                                        <td className="border border-gray-300 p-2">Estonia</td>
                                        <td className="border border-gray-300 p-2">Kristel Lään-Saarik</td>
                                        <td className="border border-gray-300 p-2">University of Tartu Counselling Center: What do we do, and how do we work?</td>
                                        <td className="border border-gray-300 p-2">June 3, 14.00</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">4</td>
                                        <td className="border border-gray-300 p-2">Estonia</td>
                                        <td className="border border-gray-300 p-2">Tõnu Jürjen and Sharipha Rzayeva</td>
                                        <td className="border border-gray-300 p-2">Estonian Group format (prevention and intervention, peer support)</td>
                                        <td className="border border-gray-300 p-2">May 13, 14:00 (May 20, 14:00 backup)</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">5</td>
                                        <td className="border border-gray-300 p-2">German</td>
                                        <td className="border border-gray-300 p-2">Brigitte Reysen-Kostudis (FU Berlin)</td>
                                        <td className="border border-gray-300 p-2">Psychological support and Mental Wellbeing</td>
                                        <td className="border border-gray-300 p-2">May 29, 16.00</td>
                                    </tr>
                                    <tr className="border border-gray-300">
                                        <td className="border border-gray-300 p-2">6</td>
                                        <td className="border border-gray-300 p-2">Together</td>
                                        <td className="border border-gray-300 p-2">Lars Kuchinke (IPU)</td>
                                        <td className="border border-gray-300 p-2">Joint webinar on documents</td>
                                        <td className="border border-gray-300 p-2">June 13, 14.00?</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}

                    </div>
                } />
        </div>
    );
}

export default TrainingProgram;
