import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryWebinar89() {
    const images = [];
    for (let i = 1; i <= 25; i++) {
        images.push({
            original: `/gallery/webinar-89/${i}.png`,
            thumbnail: `/gallery/webinar-89/thumbnails/${i}.png`,
        });
    }

    return (
        <div>
            <Content
                title={`Webinars 8-9 "Psychological Support Center" at the University of Latvia and "Experience in teaching humanitarian courses" at the University of Tartu (May 22-23, 2024)`}
                content={<ImageGallery items={images} showPlayButton={false} />}
            />
        </div>
    );
}

export default GalleryWebinar89;
