import Content from '../components/content';

function About() {
    return (
        <div>
            <Content
                title=''
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, ul {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>
                        <div className="flex justify-center items-center h-screen">
                            <img className="h-50" src="/baner.jpg" alt="burnLogo" />
                        </div> 
                        {/* <p><strong>Project Duration</strong>: 01 January 2024 -31 December 2026</p>
                        <p><strong>Priority</strong>: A stronger Europe in the world</p>
                        <p><strong>Grant holder</strong>: Ternopil Volodymyr Hnatiuk National Pedagogical University, Ukraine</p>

                        <p><strong>Project name</strong>: Boosting University Psychological Resilience and Wellbeing in (Post-) War Ukrainian Nation</p>
                        <p><strong>Project number</strong>: ERASMUS-EDU-2023-CBHE - 101129379</p>
                        <p><strong>Priority</strong>: A stronger Europe in the world</p>
                        <p><strong>Project duration</strong>: 01 January 2024 - 31 December 2026</p>
                        <p><strong>Grant holder</strong>: Ternopil Volodymyr Hnatiuk National Pedagogical University, Ukraine</p>
                        <div className="flex justify-center items-center h-screen">
                            <img className="h-50" src="/burnLogo.png" alt="burnLogo" />
                        </div> */}

                        <p><strong>Project goals and objectives</strong>:</p>
                        <p style={{ textIndent: '1.25em' }}>This project aims to increase the importance of addressing trauma and self-healing, develop expertise in providing psychological help at UA HEIs (including 3 temporarily relocated universities), and encourage psychological resilience and mindfulness among UA citizens affected by the war. Its impact demands long-term solutions, including investing in mental health services, education and awareness raising. The project objectives are to professionalize crisis psychology at university by introducing new courses; to improve psychological service provided by universities; to establish Centers for Psychological Resilience and Wellbeing; to disseminate the experience.</p>

                        <p><strong>Activities</strong>:</p>
                        <p style={{ textIndent: '1.25em' }}>BURN project serves as an umbrella for collaboration between 3 EU HEIs from Estonia, Germany and Latvia and 5 UA universities (three of them were severely affected by the war and had to relocate to safer regions with the teaching staff and students scattered all over Ukraine and Europe). BURN activities include online experience sharing webinars, study visits to Latvia, Estonia, Germany, developing two courses (BA and MA) and a module for PhD students, online roundtable discussions, autumn/spring summer schools for psychologists, training sessions for the university community, international hybrid conference and launching the Centres of Psychological Resilience and Wellbeing.</p>

                        <p><strong>Expected results</strong>:</p>
                        <p style={{ textIndent: '1.25em' }}>Ukrainian project participants will have a unique possibility to generate and transfer both European experience and ideas on improving educational and psychological services, offering psychological support, strengthening international cooperation in the process of post-war restoration. The Centers for Psychological Resilience and Wellbeing will innovate the UA universities and empower them with tools and knowledge for psychological rehabilitation, increase their ties with the local government bodies and communities. The new courses and university centers will also help the psychology students develop communication, ethics, problem solving, organization, and research skills, commitment to learning, and emotional stability.</p>

                        <p><strong>Direct target groups</strong>:</p>
                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>psychology students enhancing skills in trauma-sensitive support;</li>
                            <li>university psychological service workers adopting EU best practices and aiding IDPs and veterans;</li>
                            <li>Ukrainian HEI members utilizing improved psychological services and promoting trauma-sensitive environments;</li>
                            <li>local actors learning psychosocial assistance for war-affected individuals.</li>
                        </ul>

                        <p><strong>Indirectly targeted groups</strong>:</p>
                        <ul style={{ marginInlineStart: '1.25em' }}>
                            <li>war-affected individuals, benefiting from heightened awareness of resilience strategies, reduced stigma, and improved access to psychological support.</li>
                        </ul>
                    </div>
                } />
        </div>
    );
}

export default About;
