import Content from '../../components/content';

function Webinar14() {
    return (
        <div>
            <Content
                title={`Study Visit on Psychological Resilience and Training in Latvia (September 23-27, 2024)`}
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, img {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>

                        <p style={{ textIndent: '1.25em' }}>From 23 to 27 September 2024, the University of Latvia hosted an important study visit as part of the Erasmus+ KA project "Boosting University Psychological Resilience and Wellbeing in (Post-) War Ukrainian Nation" (BURN). This visit brought together mental health professionals, educators, and university representatives from Ukraine to explore Latvia's approaches to psychologist training, mental health promotion in schools, and trauma intervention strategies.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-14/1.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>The programme began with a comprehensive session on psychologist training in Latvia, led by Laura Pirsko, Director of the Master’s Program. Participants gained valuable insights into the structured path from undergraduate studies to professional qualification. A tour of the historic University of Latvia building followed, offering a glimpse into the university’s rich academic history. Throughout the visit, participants attended sessions focused on psychology training methods for pre- and in-service teachers, aimed at promoting mental health in educational settings. Led by Prof. Baiba Martinsone, these discussions highlighted key strategies for fostering resilience in schools. In addition, the group explored training methods for PhD students and observed undergraduate courses on basic counselling skills, guided by experienced psychology trainers.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-14/2.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>The study visit also provided participants with the opportunity to engage in workshops on course development, team building, and cognitive behavioural therapy (CBT) techniques for working with trauma, led by clinical supervisor Prof. Ieva Bite. These sessions fostered collaboration and shared best practices for psychological education. Participants visited key psychological service providers in Latvia, including the Crisis Centre "Skalbes," where they explored crisis counselling services, and the Centre for Safe Childhood "Dardedze," which focuses on violence prevention and psychological support for victims. The group also visited the "Child House" at the Children’s Clinical University Hospital, which provides specialized care for abused children.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-14/3.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>The study visit concluded with a reflective session, summarizing key learnings and discussing future collaborations. The exchange of knowledge during this visit is expected to strengthen psychological resilience and wellbeing initiatives in Ukraine, furthering the mission of the BURN project.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-14/4.jpg" alt="burnLogo" />
                        </div>
                    </div>
                } />
        </div>
    );
}

export default Webinar14;
