import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryWebinar7() {
    const images = [];
    for (let i = 1; i <= 20; i++) {
        images.push({
            original: `/gallery/webinar-7/${i}.png`,
            thumbnail: `/gallery/webinar-7/thumbnails/${i}.png`,
        });
    }

    return (
        <div>
            <Content
                title={`Webinar 7 "Working with Groups" at the University of Tartu (May 20, 2024)`}
                content={<ImageGallery items={images} showPlayButton={false} />}
            />
        </div>
    );
}

export default GalleryWebinar7;
