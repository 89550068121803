/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import Content from "../components/content";

const Home = () => {
    return (
        <div>
            <Content title="" content={
                <><div className="bg-white w-full flex sm:justify-center items-center flex-col h-[250px]">
                    <h1 className="text-4xl sm:text-6xl mt-4 text-primary-custom font-primary-custom text-center z-10">
                        Boosting University Psychological Resilience and Wellbeing in (Post-) War Ukrainian Nation
                    </h1>
                    <br />
                    <h1 className="text-2xl sm:text-4xl text-secondary-custom text-center font-primary z-10">
                        <strong>Welcome to BURN</strong>
                    </h1>
                    <h1 className="text-xl sm:text-2xl text-secondary-custom text-center font-primary z-10">
                        <strong>stronger Europe in the world</strong>
                    </h1>
                </div><p></p><div className="bg-white mt-6 border-t-2 border-t-primary-second-custom h-2 w-full border-b-2 border-b-secondary-custom">
                    </div><div className="bg-white max-w-7xl w-full">
                        <div className="flex justify-center font-bold w-full text-xl text-center font-secondary-custom text-secondary-custom mt-8">
                            <h1 className="font-bold w-full text-2xl text-center font-primary text-secondary-custom mt-8">
                                Recent Posts
                            </h1>
                        </div>
                        <div className="flex justify-center mx-auto mt-6 w-full">
                            <div className="max-w-sm bg-primary-custom border border-gray-200 rounded-lg shadow m-2 w-full">
                                <img className="rounded-t-lg" src="/docs/images/blog/image-1.jpg" alt="" />
                                <div className="p-5">
                                    <h5 className="mb-2 text-2xl font-primary font-bold tracking-tight text-white text-center">
                                        About BURN project
                                    </h5>
                                    <p className="mb-3 font-normal text-white font-secondary-custom dark:text-gray-400">
                                        Boosting University Psychological Resilience and Wellbeing in (Post-) War Ukrainian Nation
                                    </p>
                                    <Link to="/about">
                                        <button type="submit" className="inline-flex items-center px-4 py-2 bg-secondary-custom dark:bg-gray-200 border border-transparent rounded-md font-semibold text-xs text-white dark:text-gray-800 uppercase tracking-widest hover:bg-gray-700 dark:hover:bg-white focus:bg-gray-700 dark:focus:bg-white active:bg-gray-900 dark:active:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition ease-in-out duration-150">
                                            Read more
                                            <svg className="w-3.5 h-3.5 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"></path>
                                            </svg>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="max-w-sm bg-primary-custom border border-gray-200 rounded-lg shadow m-2 w-full">
                                <img className="rounded-t-lg" src="/docs/images/blog/image-1.jpg" alt="" />
                                <div className="p-5">
                                    <h5 className="mb-2 text-2xl font-primary font-bold tracking-tight text-white text-center">
                                        Erasmus+ KA2 Project Kick-off
                                    </h5>
                                    <p className="mb-3 font-normal text-white font-secondary-custom dark:text-gray-400">
                                        Erasmus+ KA2 Project Kick-off at University of Tartu (12-14 February 2024)
                                    </p>
                                    <Link to="/kick-off-meeting">
                                        <button type="submit" className="inline-flex items-center px-4 py-2 bg-secondary-custom dark:bg-gray-200 border border-transparent rounded-md font-semibold text-xs text-white dark:text-gray-800 uppercase tracking-widest hover:bg-gray-700 dark:hover:bg-white focus:bg-gray-700 dark:focus:bg-white active:bg-gray-900 dark:active:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition ease-in-out duration-150">
                                            Read more
                                            <svg className="w-3.5 h-3.5 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"></path>
                                            </svg>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="max-w-sm bg-primary-custom border border-gray-200 rounded-lg shadow m-2 w-full">
                                <img className="rounded-t-lg" src="/docs/images/blog/image-1.jpg" alt="" />
                                <div className="p-5">
                                    <h5 className="mb-2 text-2xl font-primary font-bold tracking-tight text-white text-center">
                                        Training programs
                                    </h5>
                                    <p className="mb-3 font-normal text-white font-secondary-custom dark:text-gray-400">
                                        Online webinars “Course design principles in the EU”
                                    </p>
                                    <Link to="/training-programs">
                                        <button type="submit" className="inline-flex items-center px-4 py-2 bg-secondary-custom dark:bg-gray-200 border border-transparent rounded-md font-semibold text-xs text-white dark:text-gray-800 uppercase tracking-widest hover:bg-gray-700 dark:hover:bg-white focus:bg-gray-700 dark:focus:bg-white active:bg-gray-900 dark:active:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition ease-in-out duration-150">
                                            Read more
                                            <svg className="w-3.5 h-3.5 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"></path>
                                            </svg>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div><div className="w-full bg-white max-w-7xl">
                        <h1 className="font-bold w-full text-2xl text-center font-primary text-secondary-custom mt-8">
                            Partners
                        </h1>

                        <div className="flex justify-between items-center gap-4 p-4 mt-6 mx-auto">
                            <img className="h-12 grayscale hover:grayscale-0" src="/partners-images/InternationalPsychoanalyticUniversityBerlin.png" alt="InternationalPsychoanalyticUniversityBerlin" />
                            <img className="h-12 grayscale hover:grayscale-0" src="/partners-images/TernopilVolodymyrHnatiukNationalPedagogicalUniversity.png" alt="TernopilVolodymyrHnatiukNationalPedagogicalUniversity" />
                            <img className="h-12 grayscale hover:grayscale-0" src="/partners-images/UniversityofLatvia.png" alt="UniversityofLatvia" />
                            <img className="h-12 grayscale hover:grayscale-0" src="/partners-images/KryvyiRihStatePedagogicalUniversity.png" alt="KryvyiRihStatePedagogicalUniversity" />
                            <img className="h-12 grayscale hover:grayscale-0" src="/partners-images/UniversityofTartu.png" alt="UniversityofTartu" />
                            <img className="h-12 grayscale hover:grayscale-0" src="/partners-images/KhersonStateUniversity.png" alt="KhersonStateUniversity" />
                            <img className="h-12 grayscale hover:grayscale-0" src="/partners-images/Horlivka.png" alt="HorlivkaInstituteforForeignLanguagesofDonbasStatePedagogicalUniversity" />
                            <img className="h-12 grayscale hover:grayscale-0" src="/partners-images/BogdanKhmelnitskyMelitopolStatePedagogicalUniversity.png" alt="BogdanKhmelnitskyMelitopolStatePedagogicalUniversity" />
                        </div>
                        <div className="flex m-2 justify-end text-xl text-white hover:text-secondary-custom">
                            <Link className="inline-flex items-center px-1 text-base font-medium leading-5 text-primary-custom dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 dark:hover:border-gray-700 focus:outline-none focus:text-gray-700 dark:focus:text-gray-300 focus:border-gray-300 dark:focus:border-gray-700 transition duration-150 ease-in-out text-white" to="/partners">
                                <button type="button" className="inline-flex items-center px-4 py-2 bg-primary-custom dark:bg-gray-800 border border-gray-300 dark:border-gray-500 rounded-md font-semibold text-xs text-white dark:text-gray-300 uppercase tracking-widest shadow-sm hover:bg-primary-custom-second dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-25 transition ease-in-out duration-150">
                                    More
                                    <svg className="w-3.5 h-3.5 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"></path>
                                    </svg>
                                </button>
                            </Link>
                        </div>
                    </div></>
            } />
        </div>
    );
};

export default Home;
