import Content from '../../components/content';

function Webinar12() {
    return (
        <div>
            <Content
                title={`BURN Project Steering Committee Convenes to Advance Mental Health Efforts in Ukrainian Universities (June 12, 2024)`}
                content={
                    <div className="text-xl ck-content font-secondary-custom mt-6">
                        <style>
                            {`
                                .ck-content p, img {
                                    margin-bottom: 1em;
                                    text-align: justify;
                                }
                            `}
                        </style>

                        <p style={{ textIndent: '1.25em' }}>Key Discussion Points:</p>

                        <p style={{ textIndent: '1.25em' }}>Project Management and Coordination (WP 1): Led by Ternopil Volodymyr Hnatiuk National Pedagogical University and the University of Tartu, this workstream provided updates on EACEA reporting procedures, national protocols, and dissemination strategies. Additionally, templates for event reporting and technical reports for specific project milestones (M1-M6) were addressed.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-12/1.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>Professionalization of Crisis Psychology (WP 2): The University of Latvia presented a wrap-up of their experience-sharing webinars. They also discussed the formation of course development teams and leaders, along with a forthcoming study visit to Latvia.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-12/3.jpg" alt="burnLogo" />
                        </div>

                        <p style={{ textIndent: '1.25em' }}>Improvement of University Psychological Services (WP 3): The University of Tartu elaborated on their work in this area.</p>
                        <p style={{ textIndent: '1.25em' }}>Centers for Psychological Resilience and Wellbeing (WP 4): The International Psychoanalytic University Berlin shared details regarding their upcoming study visit and plans for autumn and spring schools, along with other relevant issues.</p>
                        <p style={{ textIndent: '1.25em' }}>Dissemination (WP 5): Kryvyi Rih State Pedagogical University presented progress on the project website, development of project pages on participating universities' websites, and the creation of a contact list to facilitate dissemination efforts.</p>
                        <p style={{ textIndent: '1.25em' }}>Quality Assurance (WP 6): State Higher Educational Institution Donbas State Pedagogical University provided updates on quality assurance measures, including feedback mechanisms from the project kick-off and webinars. The scheduling of Quality Assurance Advisory Board meetings was also discussed.</p>

                        <p style={{ textIndent: '1.25em' }}>Looking Ahead:</p>
                        <p style={{ textIndent: '1.25em' }}>This meeting signifies a critical step forward in the project's mission to empower and strengthen the psychological resilience and well-being of Ukraine's university community, particularly in the face of post-war challenges. We anticipate productive discussions and actionable outcomes that will propel the project's success in the coming months.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-12/4.jpg" alt="burnLogo" />
                        </div>
                        <p style={{ textIndent: '1.25em' }}>Stay Informed:</p>
                        <p style={{ textIndent: '1.25em' }}>For more information and regular updates, please visit our project website and follow our social media channels.  Your continued support and engagement are invaluable as we work collaboratively to make a lasting impact on Ukrainian university communities.</p>

                        <div className="flex justify-center items-center h-screen">
                            <img className="h-auto max-w-xl" src="/images/webinar-12/5.jpg" alt="burnLogo" />
                        </div>
                    </div>
                } />
        </div>
    );
}

export default Webinar12;
