import Content from '../components/content';
import { Link } from 'react-router-dom';

function NewsAndMeeting() {
    return (
        <div>
            <Content
                title='News and meeting'
                content={
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-4 m-2">
                        <Link to="/kick-off-meeting" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/kick-off-meeting/image7.png" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">"BURN" Erasmus + KA2 Project Kick-off</h5>
                                <p className="mb-3 font-normal font-secondary text-md">The Erasmus+ KA2 project "BURN" at University of Tartu <br />(12-14 February 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/estonian-experience/a1.jpg" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">Estonian experience in syllabus development</h5>
                                <p className="mb-3 font-normal font-secondary text-md">Webinar 1 "Estonian experience in syllabus development" at University of Tartu <br />(April 22, 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar-2" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/developing-experience/1.jpg" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">Experience of developing syllabuses by Latvian Universities</h5>
                                <p className="mb-3 font-normal font-secondary text-md">Webinar 2 "Experience of developing syllabuses by Latvian Universities" at University of Latvia<br />(April 29, 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar-3" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-3/2.png" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">Germany's experience at International Psychoanalytic University Berlin GmbH</h5>
                                <p className="mb-3 font-normal font-secondary text-md">Webinar 3 "Germany's Syllabus Development Experience" at International Psychoanalytic University Berlin GmbH<br />(May 7, 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar-4" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-4/1.png" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">"Center for Psychological Resilience and Wellbeing" at University of Latvia</h5>
                                <p className="mb-3 font-normal font-secondary text-md">Webinar 4 "Center for Psychological Resilience and Wellbeing" at University of Latvia<br />(May 8, 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar-5" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-5/b2.jpg" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">"Counselling Centre" at University of Tartu</h5>
                                <p className="mb-3 font-normal font-secondary text-md">Webinar  5 "Counselling Centre" at University of Tartu<br />(May 13, 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar-6" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-6/3.png" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">Course Evaluation at International Psychoanalytic University Berlin GmbH</h5>
                                <p className="mb-3 font-normal font-secondary text-md">Webinar 6 "Course Evaluation" at International Psychoanalytic University Berlin GmbH<br />(May 14, 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar-7" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-7/1.png" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">Working with Groups at the University of Tartu</h5>
                                <p className="mb-3 font-normal font-secondary text-md">Webinar 7 "Working with Groups" at the University of Tartu<br />(May 20, 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar-89" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-7/1.png" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">Psychological Counseling Service and Experience in teaching humanitarian courses</h5>
                                <p className="mb-3 font-normal font-secondary text-md">Webinars 8-9 "Psychological Support Center" at the University of Latvia and "Experience in teaching humanitarian courses" at the University of Tartu<br />(May 22-23, 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar-10" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-10/6.png" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">Course Design and Evaluation</h5>
                                <p className="mb-3 font-normal font-secondary text-md">Webinar 10 "Course Design and Evaluation" at Ternopil Volodymyr Hnatiuk National Pedagogical University<br />(May 27, 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar-11" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-11/1.png" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">Psychological Counseling Service</h5>
                                <p className="mb-3 font-normal font-secondary text-md">Webinar 11 "Freie Universität Berlin's Psychological Counseling Service"<br />(May 29, 2024)</p>
                            </div>
                        </Link>
                        <Link to="/webinar-12" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-12/1.jpg" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">BURN Project Steering Committee Convenes <br/> (June 12, 2024)</h5>
                            </div>
                        </Link>
                        <Link to="/webinar-13" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-13/11.jpg" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">Experience Sharing Webinar <br/> (June 13, 2024)</h5>
                            </div>
                        </Link>
                        <Link to="/webinar-14" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-14/1.jpg" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">Study Visit on Psychological Resilience and Training in Latvia <br/> (September 23-27, 2024)</h5>
                            </div>
                        </Link>
                        <Link to="/webinar-15" className="flex mt-2 flex-col items-center bg-white border border-gray-300 rounded-lg shadow md:flex-row md:max-w-xl">
                            <img className="object-cover w-full m-2 rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src="/images/webinar-15/1.jpg" alt="" />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-xl font-bold tracking-tight font-primary">BURN Project Study Visit: Strengthening Psychological Resilience<br/>(Berlin, November 4-8, 2024)</h5>
                            </div>
                        </Link>
                    </div>
                } />
        </div>
    );
}

export default NewsAndMeeting;
