import Content from '../../components/content';
import ImageGallery from "react-image-gallery";

function GalleryKickOffAtUniversity() {
    const images = [];
    for (let i = 1; i <= 42; i++) {
        images.push({
            original: `/gallery/kick-off-at-university/${i}.jpg`,
            thumbnail: `/gallery/kick-off-at-university/thumbnails/${i}.jpg`,
        });
    }

    return (
        <div>
            <Content
                title='Kick-off at University of Tartu (12-14 February 2024)'
                content={
                    <ImageGallery items={images} showPlayButton={false} />
                } />
        </div>
    );
}

export default GalleryKickOffAtUniversity;
